// const cmnMsCtfc = {
//   callback: null,
//   methods: {
//     msfCtfc (keepData) {
//       this.callback = keepData.callback
//       this.$AxiosBuilder
//         .data(JSON.stringify(keepData))
//         .build()
//         .post('/mb/mba/msfCtfcStart')
//         .then((res) => {
//           console.log(res)
//           const data = res.data
//           if (data.succ) {
//             for (let i = 0; i < document.reqKMCISForm.length; i++) {
//               if (document.reqKMCISForm[i].name === 'tr_cert') {
//                 document.reqKMCISForm[i].value = data.tr_cert
//               } else if (document.reqKMCISForm[i].name === 'tr_url') {
//                 document.reqKMCISForm[i].value = data.tr_url
//               } else if (document.reqKMCISForm[i].name === 'tr_add') {
//                 document.reqKMCISForm[i].value = data.tr_add
//               }
//             }
//             console.dir(document.reqKMCISForm)
//             this.openKMCISWindow()
//           }
//         })
//         .catch((err) => {
//           console.log('error')
//           console.log(err)
//         })
//     },
//     ipinCtfc (keepData) {
//       // 마케팅 채널 json형식으로 변환
//       this.callback = keepData.callback
//       this.$AxiosBuilder
//         .data(JSON.stringify(keepData))
//         .build()
//         .post('/mb/mba/ipinCtfcStart')
//         .then((res) => {
//           console.log(res)
//           const data = res.data
//           if (data.succ) {
//             console.log('=========popupUrl===========')
//             console.log(data.popupUrl)
//             for (let i = 0; i < document.form1.length; i++) {
//               if (document.form1[i].name === 'tc') {
//                 document.form1[i].value = data.tc
//               } else if (document.form1[i].name === 'cpCd') {
//                 document.form1[i].value = data.cpCd
//               } else if (document.form1[i].name === 'mdlTkn') {
//                 document.form1[i].value = data.mdlTkn
//               }
//             }
//             this.openIPINWindow(data)
//           }
//         })
//         .catch((err) => {
//           console.log('error')
//           console.log(err)
//         })
//     },
//     openKMCISWindow () {
//       let kmcisWindow
//       const UserAgent = navigator.userAgent
//       /* 모바일 접근 체크 */
//       // 모바일일 경우 (변동사항 있을경우 추가 필요)
//       if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
//         document.reqKMCISForm.target = ''
//       } else { // 모바일이 아닐 경우
//         kmcisWindow = window.open('', 'KMCISWindow', 'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250')
//         window.removeEventListener('message', this.msfCtfcCallback)
//         window.removeEventListener('message', this.ipinCtfcCallback)
//         window.addEventListener('message', this.msfCtfcCallback, false)
//         if (kmcisWindow == null) {
//           this.$alert(' ※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n    화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.')
//         }

//         document.reqKMCISForm.target = 'KMCISWindow'
//       }

//       document.reqKMCISForm.action = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp'
//       document.reqKMCISForm.submit()
//     },
//     openIPINWindow (data) {
//       let ipinWindow
//       const UserAgent = navigator.userAgent
//       /* 모바일 접근 체크 */
//       // 모바일일 경우 (변동사항 있을경우 추가 필요)
//       if (UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null) {
//         document.form1.target = ''
//       } else { // 모바일이 아닐 경우
//         ipinWindow = window.open('', 'IPINWindow', 'width=425, height=580, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250')
//         window.removeEventListener('message', this.msfCtfcCallback)
//         window.removeEventListener('message', this.ipinCtfcCallback)
//         window.addEventListener('message', this.ipinCtfcCallback, false)
//         if (ipinWindow == null) {
//           this.$alert(' ※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n    화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.')
//         }

//         document.form1.target = 'IPINWindow'
//       }

//       document.form1.action = data.popupUrl
//       document.form1.submit()
//     },
//     msfCtfcCallback (event) {
//       console.log('msfCtfcCallback origin: ', event.origin) // 메시지를 보낸 곳
//       console.log('msfCtfcCallback message: ', event.data) // 보낸 메시지
//       console.log('===============')

//       if (event.data && event.data.includes('msfSucc')) {
//         const randomStr = event.data.split(':')[1]
//         this.getMsfInfo(randomStr)
//       }
//       window.removeEventListener('message', this.msfCtfcCallback)
//       window.removeEventListener('message', this.ipinCtfcCallback)
//     },
//     ipinCtfcCallback (event) {
//       console.log('ipinCtfcCallback origin: ', event.origin) // 메시지를 보낸 곳
//       console.log('ipinCtfcCallback message: ', event.data) // 보낸 메시지

//       if (event.data && event.data.includes('ipinSucc')) {
//         const randomStr = event.data.split(':')[1]
//         this.getIpinInfo(randomStr)
//       }
//       window.removeEventListener('message', this.msfCtfcCallback)
//       window.removeEventListener('message', this.ipinCtfcCallback)
//     },
//     getMsfInfo (randomStr) {
//       this.$AxiosBuilder
//         .data(randomStr)
//         .build()
//         .post('/mb/mba/getMsfInfo')
//         .then((res) => {
//           console.log(res.data)
//           if (JSON.stringify(res.data) !== '{}') {
//             const data = res.data

//             if (typeof this.callback === 'function') {
//               // this.callback(data.CI)
//               this.callback(data.ECI, randomStr, 'msf') // 2024.02.26. CI 위변조 방지 - CI 유효성 검사 문자열 전달. by emyang.
//             }
//           } else {
//             this.$alert('본인인증을 실패했습니다.')
//           }
//         })
//         .catch((err) => {
//           console.log('error')
//           console.log(err)
//         })
//     },
//     getIpinInfo (randomStr) {
//       this.$AxiosBuilder
//         .data(randomStr)
//         .build()
//         .post('/mb/mba/getIpinInfo')
//         .then((res) => {
//           console.log(res.data)
//           console.log(JSON.stringify(res.data) !== '{}')
//           if (JSON.stringify(res.data) !== '{}') {
//             const data = res.data

//             if (typeof this.callback === 'function') {
//               // this.callback(data.CI)
//               this.callback(data.ECI, randomStr, 'ipin') // 2024.02.26. CI 위변조 방지 - CI 유효성 검사 문자열 전달. by emyang.
//             }
//           } else {
//             this.$alert('아이핀 인증을 실패했습니다.')
//           }
//         })
//         .catch((err) => {
//           console.log('error')
//           console.log(err)
//         })
//     }
//   }
// }

const cmnMsStr = {
  data () {
    return {
      srchKeywordList: [],
      geoData: {
        geolocationAgrYn: 'N',
        strLttd: 0.0,
        strLitd: 0.0,
        flag: null
      }
    }
  },
  async mounted () {
    await this.selStrSrchKeyword()
  },

  methods: {
    selStrSrchKeyword () {
      this.$AxiosBuilder
        .data()
        .build()
        .post('/ms/msg/selStrSrchKeyword')
        .then((res) => {
          this.srchKeywordList = res.data.data
        })
    },
    querySearch (queryString, cb) {
      const links = this.srchKeywordList
      const results = links.filter(this.createFilter(queryString)).map(({ strCd, strNm }) => ({ label: strNm, value: strCd }))
      // call callback function to return suggestions
      cb(results)
    },
    createFilter (queryString) {
      const lowerCase = queryString.toLowerCase()
      const set = []
      return (item) => {
        const retVal = !set.includes(item.strCd) && (
          item.strNm.toLowerCase().includes(lowerCase) || (item.strAddr && item.strAddr.toLowerCase().includes(lowerCase)))
        if (retVal) {
          set.push(item.strCd)
        }
        return retVal
      }
    },
    async selStrOne (strCd) {
      let str = null
      await this.$AxiosBuilder
        .data({ inclusiveStrCd: strCd })
        .build()
        .post('/ms/msg/selStr')
        .then((res) => {
          if (res.data.data) {
            str = res.data.data[0]
            Promise.resolve()
          }
        })
        .catch((error) => {
          Promise.reject(error)
        })

      return str
    },

    checkMobile () {
      const varUA = navigator.userAgent.toLowerCase() // userAgent 값 얻기

      if (varUA.includes('android')) {
        // 안드로이드
        return 'AOS'
      } else if (varUA.includes('iphone') || varUA.includes('ipad') || varUA.includes('ipod')) {
        // IOS
        return 'IOS'
      } else if (varUA.includes('app_daiso_ios') || varUA.includes('app_daiso') || varUA.includes('app_daiso_android')) {
        // APP
        return 'APP'
      } else {
        // 아이폰, 안드로이드 외

        return 'PC'
      }
    },

    selGeolocation () {
      this.$AxiosBuilder
        .data({ mbMngNo: this.$store.getters['session/getMbMngNo'], mbEid: this.$store.getters['session/getMbEid'], lcInfoGetSeCd: this.checkMobile() })
        .build()
        .post('/ms/msg/selGeolocationAgrYn')
        .then((res) => {
          if (res.data.data) {
            if (res.data.data.geolocationAgrYn === 'Y') {
              this.getGeolocation(this.geoData)
            } else {
              this.geoData.flag = false
            }
          }
        })
    }

  }
}

const cmnOr = {
  methods: {
    getPath (or) {
      let url = ''

      // 일반주문 택배배송
      if (or.orTyCd === '01' && or.dlvFomCd === '01') {
        url = '/or/orb/SCR_ORB_0002'
      // 일반주문 매장픽업
      } else if (or.orTyCd === '01' && or.dlvFomCd === '02') {
        url = '/or/orb/SCR_ORB_0002'
      // 정기주문 택배배송
      } else if (or.orTyCd === '02' && or.dlvFomCd === '01') {
        url = '/or/orb/SCR_ORB_0003'
      // 대량주문 용차배송
      } else if (or.orTyCd === '03' && or.dlvFomCd === '03') {
        url = '/or/orb/SCR_ORB_0006'
      // 대량주문 매장픽업
      } else if (or.orTyCd === '03' && or.dlvFomCd === '02') {
        url = '/or/orb/SCR_ORB_0006'
      // 상품권
      } else if (or.orTyCd === '01' && or.gfYn === 'Y') {
        url = '/or/orb/SCR_ORB_0004'
      }
      return url
    },
    moveDtl (item, router) {
      const path = this.getPath(item)
      if (path) {
        router.push({
          path,
          query: { orNo: item.orNo }
        })
      }
    }
  }
}

export {
  // cmnMsCtfc,
  cmnMsStr,
  cmnOr
}
