export default async function ({ redirect, $axios, store, commit, app }) {
  // 사용자가 인증된 상태가 아니면 로그인 화면으로 보낸다.
  let isLogin = true
  await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/token')
    .then((res) => {
      isLogin = res.success
    })
    .catch(() => { isLogin = false })

  if (!isLogin) {
    // 세션삭제
    store.commit('session/setMember', {})
  } else {
    // store에 member 정보 없을 시, accessToken 값으로 store member 값을 셋팅한다. (브라우저 껐다 켰을경우)
    if (store.getters['session/getMbMngNo'] === undefined) {
      await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/getMbInfoByToken')
        .then((res) => {
          if (res.success) {
            store.commit('session/setMember', res.data)
          }
        })
    }
    // 23.10.18 회원팀 수정 => 로그인 된 상태에서 로그인 화면 접근 시, 메인 페이지로 이동한다.
    try {
      if (app.context.req !== undefined) {
        if (app.context.req.url === '/mb/mbb/SCR_MBB_0097A' || app.context.req.url === '/mb/mbb/SCR_MBB_0097B') {
          redirect(('/ds'))
        }
      }
    } catch (e) {
      console.log('token.js error ==> ', e)
    }
  }
}
