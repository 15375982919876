
import ScrMbc0110b01 from '~/pages/mb/mbc/SCR_MBC_0110B_01.vue'

export default {
  components: {
    ScrMbc0110b01
  },
  layout: 'default',
  props: ['error'],
  data () {
    return {
      mbMngNo: this.$store.getters['session/getMbMngNo'],
      userTy: this.$store.getters['session/getUserTy'],
      userSts: this.$store.getters['session/getUserSts']
    }
  },
  mounted () {
    // setTimeout(() => {
    //   this.gotoMain()
    // }, 1000)
    if (this.userSts === '3' || this.userSts === '5' || this.userSts === '6') {
      // 1. 블랙리스트
      this.$refs.mbc0110b01.form.ilusrCustMngNo = this.mbMngNo
      this.$refs.mbc0110b01.outerVisible = true
      this.$refs.mbc0110b01.selBst()
    }
  },
  methods: {
    // gotoMain () {
    //   this.$router.push('/')
    // }
    goReturnUrl () {
      this.$router.replace('/ds')
    }
  }

}
