/* eslint-disable no-undef */

const groobeeUtil = {
  methods: {
    // ex) 아래와 같이 처리 setGroobeeMeta, setGroobeeScript 등록 필수.
    // import { groobeeUtil } from '~/mixin/groobeeUtil'
    // export default {
    // mixins: [groobeeUtil],
    // head () {
    //   return {
    //     meta: [...this.setGroobeeMeta()],
    //     script: [...this.setGroobeeScript()],
    //     __dangerouslyDisableSanitizers: ['script'] // 그루비처리시 필요
    //   }
    // }
    // }
    // setGroobeeMeta () {
    //   const accountInfo = this.$store.getters['session/getAccountInfo']
    //   // const membId = accountInfo?.mbEid ?? ''
    //   const mbMngNo = accountInfo?.mbMngNo ?? ''
    //   const sexdSe = accountInfo?.sexdSe ?? ''
    //   const ageGroup = accountInfo?.ageGroup ?? ''
    //   const userTy = accountInfo?.userTy ?? ''
    //   return [
    //     { property: 'groobee:member_id', content: mbMngNo },
    //     // { property: 'groobee:mb_mng_no', content: mbMngNo },
    //     { property: 'groobee:member_grade', content: '' },
    //     { property: 'groobee:member_gender', content: sexdSe },
    //     { property: 'groobee:member_type', content: userTy },
    //     { property: 'groobee:member_age', content: ageGroup }
    //   ]
    // },
    // setGroobeeScript () {
    //   const serverSe = process.env.VUE_APP_ENV
    //   console.log('serverSe', serverSe)
    //   const serviceKeys = {
    //     loc: '56ac1391e4af4e60951b8806676ede5c',
    //     stg: '56ac1391e4af4e60951b8806676ede5c',
    //     qa: 'ace531b9f5954a96960faa1db76378d6',
    //     prd: 'ace531b9f5954a96960faa1db76378d6',
    //     default: '56ac1391e4af4e60951b8806676ede5c'
    //   }
    //   return [
    //     {
    //       innerHTML: `
    //               (function(a,i,u,e,o) {
    //                 a[u]=a[u]||function(){(a[u].q=a[u].q||[]).push(arguments)};
    //                 })(window, document, "groobee");
    //                 groobee("serviceKey", "` + (serviceKeys[serverSe] ?? serviceKeys.default) + '");' +
    //                 'groobee("siteType", "custom");'
    //     }, { src: 'https://static.groobee.io/dist/g2/groobee.init.min.js', charset: 'utf-8' }
    //     // }, { src: '/js/ui/groobee.init.min.js', charset: 'utf-8' }
    //   ]
    // },
    setGroobeeInit () {
      // window.setGroobeeRecommend를 처리하기 위한 함수
      // window.setThisComponent(this)
    },
    setGroobeeStart () {
      window.setThisComponent(this)
      try {
        console.log('groobee.start')
        groobee.start()
      } catch (e) {
        console.log(e)
      }
    },
    setGroobeeAdaptSrc (memberObj) {
      groobee.setGroobeeMemberInfo(memberObj)
    },
    // 키워드
    setGroobeeKeyword (keyword) {
      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'SE', { keyword })
        groobee.action('SE', { keyword })
      } catch (e) {
        console.log(e)
      }
    },
    // 주문
    setGroobeeOrd (goods) {
      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'OR', { goods })
        groobee.action('OR', { goods })
      } catch (e) {
        console.log(e)
      }
    },
    // 주문완료
    setGroobeeOrdCmpl (orderNo, goods) {
      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'PU', { orderNo, goods })
        groobee.action('PU', { orderNo, goods })
      } catch (e) {
        console.log(e)
      }
    },
    // 상세
    setGroobeeDtl (goods) {
      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'VG', { goods })
        groobee.action('VG', { goods })
      } catch (e) {
        console.log(e)
      }
    },
    // 상세
    setGroobeeDtlNg (goods) {
      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'VG', { goods })
        groobee('VG', { goods })
      } catch (e) {
        console.log(e)
      }
    },
    // 장바구니
    setGroobeeCart (goods) {
      if (goods?.length < 1) {
        return
      }

      window.setThisComponent(this)
      try {
        console.log('groobee.action', 'VC', { goods })
        groobee.action('VC', { goods })
      } catch (e) {
        console.log(e)
      }
    },
    // 목록카테고리
    setGroobeeCategory (category) {
      window.setThisComponent(this)
      try {
        groobee.action('CA', { category })
      } catch (e) {
        console.log(e)
      }
    },
    getGroobeePrefRecommend (area, gender, age) {
      const campaignKeys = {
        loc: 'ace531b9f5954a96960faa1db76378d6',
        dev: 'ace531b9f5954a96960faa1db76378d6',
        stg: 'ace531b9f5954a96960faa1db76378d6',
        prd: 'ace531b9f5954a96960faa1db76378d6',
        defalut: 'ace531b9f5954a96960faa1db76378d6'
      }
      try {
        console.log('getGroobeePrefRecommend', campaignKeys[campaignKeys] ?? campaignKeys.defalut, area, gender, age)
        return groobee.getGroobeeRecommend(campaignKeys[campaignKeys] ?? campaignKeys.defalut, { area, gender, age })
      } catch (e) {
        console.log(e)
        return null
      }
    },
    // 추천목록 받기
    getGroobeeRecommend (algorithmCd, campaignKey, goodsArray) {
      console.log('getGroobeeRecommend', algorithmCd, campaignKey, goodsArray)
      this.getGroobeeRecommendList(algorithmCd, campaignKey, goodsArray)
    },
    // 추천목록 중 필터된 항목 보내기
    setGroobeeRecommendTarget (algorithmCd, campaignKey, goodsArray) {
      console.log('setGroobeeRecommendTarget', algorithmCd, campaignKey, goodsArray)

      const groobeeObj = {
        algorithmCd,
        campaignKey,
        campaignTypeCd: 'RE',
        goods: goodsArray
      }
      groobee.send('DI', groobeeObj)
    },
    // 필터된 항목 중 클릭한 아이템
    setGroobeeRecommendClick (algorithmCd, campaignKey, carCd) {
      console.log('setGroobeeRecommendClick', algorithmCd, campaignKey, carCd)

      const groobeeObj = {
        algorithmCd,
        campaignKey,
        campaignTypeCd: 'RE',
        goods: [{ goodsCd: carCd }]
      }
      groobee.send('CL', groobeeObj)
    }
  }
}
export {
  groobeeUtil
}
