
import LoadingBar from '~/components/common/LoadingBar'

export default {
  name: 'DefaultLayouts',
  components: { LoadingBar },
  mounted () {
    console.log('kakao call start')
    try { window.kakaoPixel('7573203864979226263').pageView() } catch (e) {}
    console.log('kakao call end')
  }
}
