import pathMap from './pathMap.json'

export default function ({ req, redirect }) {
  if (process.server && req.headers['user-agent']) {
    const userAgent = req.headers['user-agent']
    const isMobile = /mobile/i.test(userAgent)

    if (isMobile) {
      const url = req.url
      const mobileUrl = process.env.MO_WEB_BASE_URL

      // URL이 JSON 객체에 정의된 경로 중 하나로 시작하는지 확인
      let redirectPath = url // 기본값은 현재 URL
      for (const [key, value] of Object.entries(pathMap)) {
        if (url.startsWith(key)) {
          const newPath = url.replace(key, value) // 일치하는 경우, 변환된 경로로 변경
          redirectPath = newPath.startsWith('/') ? newPath : '/' + newPath // 새 경로 앞에 슬래시 추가
          break
        }
      }

      const redirectUrl = mobileUrl + redirectPath
      redirect(redirectUrl)
    }
  }
}
