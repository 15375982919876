
import Topbaner from '@/components/pub/Topbaner.vue'
import HeaderCmp from '@/components/pub/Header.vue'
import FooterCmp from '@/components/pub/FooterCmp.vue'
// import QuiEl from '@/components/pub/QuiEl.vue'

export default {
  name: 'PubLayout',
  components: {
    Topbaner,
    HeaderCmp,
    FooterCmp
    // QuiEl
  },
  provide () {
    return {
      sendPageName: this.sendPageName
    }
  },
  data () {
    return {
      pageName: '',
      scrollY: null, // scrollTop
      headerSm: false, // 헤더 변환 여부
      headerAniEnd: false,
      stickyStatus: [ // sticky 상태 제어 (sticky 항목 최대 2개 예상)
        { position: 0, fix: false },
        { position: 0, fix: false },
        { position: 0, fix: false }
      ]
    }
  },
  computed: {
    injectTitle () {
      return this.pageName
    }
  },
  mounted () {
    console.log('kakao call start')
    try { window.kakaoPixel('7573203864979226263').pageView() } catch (e) {}
    console.log('kakao call end')

    window.addEventListener('load', () => {
      let timer
      // 23.08.30 퀵메뉴 show/hide 추가
      // 23.09.27 선택자 수정
      // 23.10.12 삭제 (고정요청)
      // 23.11.06 고객요청: show/hide 기능 다시 추가
      const quickEl = document.querySelector('.quick-item.fix')

      this.$refs.scrollArea.addEventListener('scroll', () => {
        this.onScroll()
        if (!timer) {
          timer = setTimeout(() => {
            timer = null
            this.headerControl(10)
            this.stickyControl('sticky-cont')
            // 23.08.30 퀵메뉴 show/hide 추가
            // 23.10.12 삭제 (고정요청)
            // 23.11.06 고객요청: show/hide 기능 다시 추가
            if (quickEl !== undefined && quickEl !== null) {
              this.quickShow(quickEl)
            }
          }, 100)
        }
      })

      const topBtn = document.getElementsByClassName('quick-btn-top')[0]
      if (topBtn !== undefined) {
        topBtn.addEventListener('click', () => {
          this.$refs.scrollArea.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        })
      }
    })
  },
  methods: {
    sendPageName (pageName) {
      this.pageName = pageName
    },
    onScroll () { // 스크롤 값 반영
      this.scrollY = Math.round(this.$refs.scrollArea.scrollTop)
      // console.log(Math.round(this.$refs.scrollArea.scrollTop))
    },
    headerControl (topPosition = 0) { // 헤더 상태 제어
      // const headerCont = this.$refs.header.$el
      if (this.scrollY >= 0) {
        if (this.scrollY > parseInt(topPosition)) {
          this.headerSm = true
        } else {
          this.headerSm = false
        }
      }
    },
    stickyControl (itmName) { // sticky 체크
      const stickyCont = document.getElementsByClassName(itmName)
      // const stickyContTop = stickyCont.getBoundingClientRect().top - this.$refs.header.$el.clientHeight

      for (let i = 0; i < stickyCont.length; i++) {
        const stickyContTop = stickyCont[i].getBoundingClientRect().top - this.$refs.header.$el.clientHeight - 16

        if (this.stickyStatus[i] !== undefined) {
          if (stickyContTop < 0 && this.stickyStatus[i].fix === false) {
            this.stickyStatus[i].position = this.scrollY
            stickyCont[i].classList.add('top-sticky')
            stickyCont[i].parentElement.classList.add('top-sticky-wrap')
            this.stickyStatus[i].fix = true
          } else if (this.stickyStatus[i].position > this.scrollY && this.stickyStatus[i].fix === true) {
            stickyCont[i].classList.remove('top-sticky')
            stickyCont[i].parentElement.classList.remove('top-sticky-wrap')
            this.stickyStatus[i].fix = false
          }
        } else {
          return
        }
      }
    },
    // 23.08.30 퀵메뉴 show/hide 추가
    // 23.11.06 고객요청: show/hide 기능 다시 추가
    quickShow (item) {
      const slideHeight = document.getElementsByClassName('main-swiper')[0].clientHeight / 2
      if (this.scrollY > slideHeight) {
        item.classList.add('on')
      } else {
        item.classList.remove('on')
      }
    }
  }
}
