export default async function ({ redirect, $axios, store, app, route }) {
  // 사용자가 인증된 상태가 아니면 로그인 화면으로 보낸다.
  let isLogin = null
  console.log('store.getters[session/getMbMngNo] ' + store.getters['session/getMbMngNo'])
  console.log('store.getters[session/getNoSessionYn] ' + store.getters['session/getNoSessionYn'])
  await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/login-check')
    .then(async (res) => {
      // store의 noSession값이 Y일 경우 개인화페이지 접근불가능
      if (!res.success || (store.getters['session/getNoSessionYn'] !== undefined &&
                  store.getters['session/getNoSessionYn'] === 'Y' &&
                  app.context.route.fullPath !== '/mb/mba/SCR_MBA_0084')) {
        await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/logout')
          .then(async (res) => {
            await this.$store.commit('session/setMember', {}) // 세션객체 초기화
            isLogin = false
          })
      } else {
        isLogin = res.success
      }
    })
    .catch(() => { isLogin = false })

  if (!isLogin) {
    redirect('/mb/mbb/SCR_MBB_0097A', {
      path: route.fullPath
    })
  }
}
