
// import '@/assets/scss/index.scss'
import { throttle } from 'lodash'
import Topbaner from '@/components/pub/Topbaner.vue'
import Header from '@/components/common/Header.vue'
import Footer from '@/components/common/footer.vue'
// import FooterCmp from '@/components/pub/FooterCmp.vue'
// import Header from '@/components/pub/Header.vue'
// import QuiEl from '@/components/pub/QuiEl.vue'

export default {
  name: 'PdDtlLayout',
  components: {
    Topbaner,
    Header,
    Footer
    // QuiEl
  },
  provide () {
    return {
      sendPageName: this.sendPageName
    }
  },
  asyncData () {},
  data () {
    return {
      pageName: '',
      scrollY: null, // scrollTop
      headerSm: false, // 헤더 변환 여부
      headerAniEnd: false,
      stickyStatus: [ // sticky 상태 제어 (sticky 항목 최대 2개 예상)
        { position: 0, fix: false },
        { position: 0, fix: false },
        { position: 0, fix: false }
      ],
      scrollLoca: 0
    }
  },
  computed: {
    injectTitle () {
      return this.pageName
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleThrottledScroll)
    this.overflowObserver.disconnect()
  },
  beforeMount () {
    window.addEventListener('scroll', this.handleThrottledScroll)
    this.handleObserver()
  },
  mounted () {
    try { window.kakaoPixel('7573203864979226263').pageView() } catch (e) {}
  },
  updated () {},
  unmounted () {
    this.$refs.scrollArea.removeEventListener('scroll')
  },
  methods: {
    handleThrottledScroll: throttle(function (e) { this.scrollEvent(e) }, 50),
    handleObserver () {
      this.overflowObserver = new MutationObserver((mutations) => {
        const classValue = mutations[0].target.getAttribute(mutations[0].attributeName)
        this.setPopupOverflow(classValue)
      })
      this.overflowObserver.observe(document.getElementsByTagName('body')[0], {
        attributes: true,
        attributeFilter: ['class']
      })
    },
    setPopupOverflow (classValue) {
      const classList = classValue.split(' ')
      if (classList.includes('el-popup-parent--hidden')) {
        if (document.getElementsByTagName('html')[0].classList.contains('over-hidden')) {
          this.scrollLoca = document.body.scrollTop
        } else {
          this.scrollLoca = window.scrollY
        }
        document.getElementsByTagName('html')[0].classList.add('over-hidden')
        document.body.scrollTo(0, this.scrollLoca)
      } else {
        document.getElementsByTagName('html')[0].classList.remove('over-hidden')
        window.scrollTo(0, this.scrollLoca)
      }
    },
    scrollEvent () {
      // console.log('------------------------------------------------------')
      // console.log('scrollArea 동작함')
      this.$nextTick(() => {
        const quickEl = this.$refs.quickEl
        this.scrollY = Math.round(window.scrollY)
        this.headerControl(10)
        this.stickyControl('sticky-cont')
        // 23.08.30 퀵메뉴 show/hide 추가
        if (quickEl !== undefined) {
          this.quickShow()
        }
      })
      // 23.08.30 퀵메뉴 show/hide 추가
    },
    sendPageName (pageName) {
      this.pageName = pageName
    },
    onScroll () { // 스크롤 값 반영
      this.scrollY = Math.round(this.$refs.scrollArea.scrollTop)
    },
    headerControl (topPosition = 0) { // 헤더 상태 제어
      // const headerCont = this.$refs.header.$el
      if (this.scrollY >= 0) {
        if (this.scrollY > parseInt(topPosition)) {
          this.headerSm = true
        } else {
          this.headerSm = false
        }
      }
    },
    stickyControl (itmName) { // sticky 체크
      const stickyCont = document.getElementsByClassName(itmName)
      // const stickyContTop = stickyCont.getBoundingClientRect().top - this.$refs.header.$el.clientHeight

      for (let i = 0; i < stickyCont.length; i++) {
        const stickyContTop = stickyCont[i].getBoundingClientRect().top - this.$refs.header.$el.clientHeight - 16

        if (this.stickyStatus[i] !== undefined) {
          if (stickyContTop < 0 && this.stickyStatus[i].fix === false) {
            this.stickyStatus[i].position = this.scrollY
            stickyCont[i].classList.add('top-sticky')
            stickyCont[i].parentElement.classList.add('top-sticky-wrap')
            this.stickyStatus[i].fix = true
          } else if (this.stickyStatus[i].position > this.scrollY && this.stickyStatus[i].fix === true) {
            stickyCont[i].classList.remove('top-sticky')
            stickyCont[i].parentElement.classList.remove('top-sticky-wrap')
            this.stickyStatus[i].fix = false
          }
        } else {
          return
        }
      }
    },
    // 23.08.30 퀵메뉴 show/hide 추가
    quickShow (item) {
      const slideHeight = document.getElementsByClassName('main-swiper')[0].clientHeight / 2
      if (this.scrollY > slideHeight) {
        item.classList.add('on')
      } else {
        item.classList.remove('on')
      }
    },
    goScrollTop () {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }
  }
}
