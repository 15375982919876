export default async function ({ redirect, $axios, store, app }) {
  // 사용자가 로그인된 상태에서 로그인,회원가입 화면 접근 시 홈(/ds)화면으로 보낸다.
  let isLogin = true
  await $axios.$post(process.env.API_BASE_URL + '/mb/mbb/login-check')
    .then((res) => {
      // store의 noSession값이 Y일 경우 개인화페이지 접근불가능
      isLogin = res.success
    })
    .catch(() => { isLogin = false })

  if (isLogin) {
    redirect(('/ds'))
  }
}
